import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cronometro"
export default class extends Controller {
  connect() {
    this.crom = setInterval(() => {
      const dataInicio = new Date();
      const dataFinal = new Date("2024-11-01T00:00:00Z");

      const diff = Math.abs(dataFinal - dataInicio);
      const segundos = Math.floor(diff / 1000) % 60;
      const minutos = Math.floor(diff / (1000 * 60)) % 60;
      const horas = Math.floor(diff / (1000 * 60 * 60)) % 24;
      const dias = Math.floor(diff / (1000 * 60 * 60 * 24));

      document.querySelector("#days").innerHTML = dias < 10 ? `0${dias}` : dias;
      document.querySelector("#hours").innerHTML = horas < 10 ? `0${horas}` : horas;
      document.querySelector("#minutes").innerHTML = minutos < 10 ? `0${minutos}` : minutos;
      document.querySelector("#seconds").innerHTML = segundos < 10 ? `0${segundos}` : segundos;
    }, 1000);
  }

  abrirWhatsappp() {
    window.open('https://api.whatsapp.com/send?phone=554588210669&text=Quero%20renovar!', '_blank')
  }

  disconnect() {
    clearInterval(this.crom);
  }
}
